import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as Localization from 'expo-localization'
import ar from '../../apps/next/public/locales/ar/common.json'
import cs from '../../apps/next/public/locales/cs/common.json'
import da from '../../apps/next/public/locales/da/common.json'
import de from '../../apps/next/public/locales/de/common.json'
import en from '../../apps/next/public/locales/en/common.json'
import es from '../../apps/next/public/locales/es/common.json'
import et from '../../apps/next/public/locales/et/common.json'
import fi from '../../apps/next/public/locales/fi/common.json'
import fr from '../../apps/next/public/locales/fr/common.json'
import is from '../../apps/next/public/locales/is/common.json'
import it from '../../apps/next/public/locales/it/common.json'
import ja from '../../apps/next/public/locales/ja/common.json'
import ko from '../../apps/next/public/locales/ko/common.json'
import lt from '../../apps/next/public/locales/lt/common.json'
import lv from '../../apps/next/public/locales/lv/common.json'
import nl from '../../apps/next/public/locales/nl/common.json'
import no from '../../apps/next/public/locales/no/common.json'
import pt from '../../apps/next/public/locales/pt/common.json'
import ru from '../../apps/next/public/locales/ru/common.json'
import sv from '../../apps/next/public/locales/sv/common.json'
import zh from '../../apps/next/public/locales/zh/common.json'
import i18nextConfig from '../../apps/next/next-i18next.config'

const resources = {
  ar: {
    common: ar,
  },
  cs: {
    common: cs,
  },
  da: {
    common: da,
  },
  de: {
    common: de,
  },
  en: {
    common: en,
  },
  es: {
    common: es,
  },
  et: {
    common: et,
  },
  fi: {
    common: fi,
  },
  fr: {
    common: fr,
  },
  is: {
    common: is,
  },
  it: {
    common: it,
  },
  ja: {
    common: ja,
  },
  ko: {
    common: ko,
  },
  lt: {
    common: lt,
  },
  lv: {
    common: lv,
  },
  nl: {
    common: nl,
  },
  no: {
    common: no,
  },
  pt: {
    common: pt,
  },
  ru: {
    common: ru,
  },
  sv: {
    common: sv,
  },
  zh: {
    common: zh,
  },
}

i18n.use(initReactI18next).init({
  lng: typeof window !== 'undefined' ? Localization.locale : 'sv',
  fallbackLng: 'en',
  compatibilityJSON: 'v3',
  resources,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n

export { resources }
