import { COLOR_MODE } from 'app/types/theme'
import { InitialStateType } from './context'
import AsyncStorage from '@react-native-async-storage/async-storage'

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export enum AuthDispatchTypes {
  InitialLoadDone = 'INITIAL_LOAD_DONE',
  SignIn = 'SIGN_IN',
  SignOut = 'SIGN_OUT',
  IncrementCart = 'INCREMENT_CART',
  SetColorMode = 'SET_COLOR_MODE',
  SetCartToken = 'SET_CART_TOKEN',
}

type AuthenticationPayload = {
  [AuthDispatchTypes.InitialLoadDone]: {}
  [AuthDispatchTypes.SignIn]: {}
  [AuthDispatchTypes.SignOut]: {}
  [AuthDispatchTypes.IncrementCart]: {}
  [AuthDispatchTypes.SetColorMode]: {
    colorMode: COLOR_MODE
  }
  [AuthDispatchTypes.SetCartToken]: {
    cartToken: string
  }
}

export type AuthenticationActions =
  ActionMap<AuthenticationPayload>[keyof ActionMap<AuthenticationPayload>]

export const authenticationReducer = (
  prevState: InitialStateType,
  action: AuthenticationActions
) => {
  switch (action.type) {
    case AuthDispatchTypes.InitialLoadDone:
      return {
        ...prevState,
        isLoading: false,
      }
    case AuthDispatchTypes.SignIn:
      return {
        ...prevState,
        signedIn: true,
      }
    case AuthDispatchTypes.SignOut:
      return {
        ...prevState,
        signedIn: false,
      }
    case AuthDispatchTypes.IncrementCart:
      return {
        ...prevState,
        itemsInCart: prevState.itemsInCart + 1,
      }
    case AuthDispatchTypes.SetColorMode:
      if (typeof window !== 'undefined') {
        AsyncStorage.setItem('colorMode', action.payload.colorMode)
      }
      return {
        ...prevState,
        theme: action.payload.colorMode,
      }
    case AuthDispatchTypes.SetCartToken:
      if (typeof window !== 'undefined') {
        AsyncStorage.setItem('cartToken', action.payload.cartToken)
      }
      return {
        ...prevState,
        cartToken: action.payload.cartToken,
      }
  }
}
