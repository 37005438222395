import * as Font from 'expo-font'
import { useEffect, useState } from 'react'

import Inter from '../../../fonts/Inter.ttf'
import Lexend from '../../../fonts/Lexend.ttf'
import RajdhaniLight from '../../../fonts/Rajdhani-Light.ttf'
import RajdhaniRegular from '../../../fonts/Rajdhani-Regular.ttf'
import RajdhaniMedium from '../../../fonts/Rajdhani-Medium.ttf'
import RajdhaniSemibold from '../../../fonts/Rajdhani-SemiBold.ttf'
import RajdhaniBold from '../../../fonts/Rajdhani-Bold.ttf'

export const useResources = () => {
  const [isFontReady, setIsFontReady] = useState(false)

  const loadFontAsync = async () => {
    try {
      await Font.loadAsync({
        Inter: {
          uri: Inter as any,
          display: Font.FontDisplay.SWAP,
        },
        Lexend: {
          uri: Lexend as any,
          display: Font.FontDisplay.SWAP,
        },
        RajdhaniLight: {
          uri: RajdhaniLight as any,
          display: Font.FontDisplay.SWAP,
        },
        RajdhaniRegular: {
          uri: RajdhaniRegular as any,
          display: Font.FontDisplay.SWAP,
        },
        RajdhaniMedium: {
          uri: RajdhaniMedium as any,
          display: Font.FontDisplay.SWAP,
        },
        RajdhaniSemibold: {
          uri: RajdhaniSemibold as any,
          display: Font.FontDisplay.SWAP,
        },
        RajdhaniBold: {
          uri: RajdhaniBold as any,
          display: Font.FontDisplay.SWAP,
        },
      })
    } catch (error) {
      console.log('Font Load Error:', error)
    } finally {
      setIsFontReady(true)
    }
  }

  useEffect(() => {
    loadFontAsync()
  }, [])

  return {
    isFontReady,
  }
}
