import { I18nextProvider, initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import { NavigationProvider } from './navigation'
import { SafeArea } from './safe-area'

import { resources } from '../i18n'
import { AuthContext, initialState } from 'app/utils/context'
import { useEffect, useReducer } from 'react'
import { AuthDispatchTypes, authenticationReducer } from 'app/utils/reducers'
import { Auth } from 'aws-amplify'
import * as Localization from 'expo-localization'
import { v4 as uuidv4 } from 'uuid'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export function Provider({
  children,
  lang,
}: {
  children: React.ReactNode
  lang?: string
}) {
  const queryClient = new QueryClient()
  const [state, dispatch] = useReducer(authenticationReducer, initialState)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => dispatch({ type: AuthDispatchTypes.SignIn, payload: {} }))
      .catch((err) => console.log(err))
      .finally(() =>
        dispatch({ type: AuthDispatchTypes.InitialLoadDone, payload: {} })
      )
  }, [])

  useEffect(() => {
    const fetchColorMode = async () => {
      const colorMode = (await AsyncStorage.getItem('colorMode')) || 'dark'
      if (colorMode === 'light' || colorMode === 'dark') {
        dispatch({
          type: AuthDispatchTypes.SetColorMode,
          payload: { colorMode },
        })
      }
    }
    fetchColorMode()
  }, [])

  useEffect(() => {
    const fetchCartToken = async () => {
      const cartToken = await AsyncStorage.getItem('cartToken')
      if (cartToken !== null) {
        dispatch({
          type: AuthDispatchTypes.SetCartToken,
          payload: { cartToken },
        })
      } else {
        let cartToken = uuidv4()
        dispatch({
          type: AuthDispatchTypes.SetCartToken,
          payload: { cartToken },
        })
      }
    }
    fetchCartToken()
  }, [])

  useEffect(() => {
    i18n.use(initReactI18next).init({
      lng: typeof window !== 'undefined' ? Localization.locale : lang,
      fallbackLng: 'en',
      compatibilityJSON: 'v3',
      resources,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    })
  }, [lang])

  return (
    <SafeArea>
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={{ state, dispatch }}>
          <I18nextProvider i18n={i18n}>
            <NavigationProvider theme={state.theme}>
              {children}
            </NavigationProvider>
          </I18nextProvider>
        </AuthContext.Provider>
      </QueryClientProvider>
    </SafeArea>
  )
}
