import { createContext, Dispatch } from 'react'
import { AuthenticationActions } from './reducers'
import { COLOR_MODE } from 'app/types/theme'

export type InitialStateType = {
  isLoading: boolean
  signedIn: boolean
  itemsInCart: number
  theme: COLOR_MODE
  cartToken: string
}

export const initialState = {
  isLoading: true,
  signedIn: false,
  itemsInCart: 0,
  theme: 'dark' as COLOR_MODE,
  cartToken: '',
}

export const AuthContext = createContext<{
  state: InitialStateType
  dispatch: Dispatch<AuthenticationActions>
}>({
  state: initialState,
  dispatch: () => null,
})
